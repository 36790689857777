.infoContainer {
  width: 100%;
  background-color: white;
  border: 1px solid #22222260;
  border-radius: 10px;
  width: 400px;
  padding: 14px 18px 28px 18px;
}

.infoContainer div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #22222240;
  padding: 10px 0px;
}

@media screen and (max-width: 560px) {
  .infoContainer {
    width: 100%;
    margin: auto;
  }
}
