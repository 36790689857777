.loginView-main-div {
  display: grid;
  justify-items: center;
  align-items: center;
}

.profile-default {
  font-size: 200px;
  color: #399b22;
}

.form-div {
  display: grid;
  gap: 30px;
  justify-items: center;
}

.label {
  font-size: 14px;
  font-weight: bold;
}

.form-field {
  width: 400px;
  height: 40px;
  font-size: 16px;
  font-family: inherit;
  border: 0.5px solid #d3d3d3;
  border-radius: 8px;
  padding: 10px;
  /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); */
}

.formSelect {
  width: 400px;
  height: 40px;
  font-size: 16px;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z" /></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.formSelect:focus {
  outline: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.form-field:focus {
  outline: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.dropDown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 10px;
  width: 400px;
  border: 1px solid lightgrey;
  background-color: #ffffff;
}
.selectOptions {
  outline: none;
  border: none;
  font-size: 12px;
  max-width: 62%;
}

.labels {
  color: gray;
}

.login-form {
  width: 400px;
}

@media screen and (max-width: 550px) {
  .profile-default {
    font-size: 120px;
  }

  .form-field {
    width: 80vw;
  }
  .formSelect {
    width: auto;
  }

  .login-form {
    width: 95%;
    margin: auto;
  }
}
