.patientHomeTableDiv {
  width: 400px;
}

.infoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0px;
  border-bottom: 1px solid #22222210;
}

.patientHomeTD {
  /* padding: 100px; */
  text-align: right;
}

.patientHomeTDQuestionFiles {
  max-width: 50px;
}

.PatientQuestionFiles {
  width: 200px;
}

@media screen and (max-width: 560px) {
  .patientHomeTableDiv {
    width: 100%;
    margin: auto;
    padding: 0;
  }
}

.progress-bar {
  width: 100%;
  height: 20px;
  margin-top: 25px;
  border-radius: 8px;
}

.progress-bar span {
  display: block;
  height: 100%;
  background-color: #399b22;
  animation: load 3s;
}


@keyframes load {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}