.main-div {
  display: grid;
  gap: 10px;
  justify-items: center;
}

.button {
  border: 2px solid #399b22;
  border-radius: 8px;
  width: 150px;
  font-size: 13px;
  font-weight: 400;
  /* box-shadow: 10px #4bc231; */
  text-shadow: 0 0 2px rgb(75, 194, 49, 0.8);
  box-shadow: 0 0 2px rgb(75, 194, 49, 0.8);
  background-color: transparent;
  color: #399b22;
  padding: 10px 0px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.arrowButton {
  width: 400px;
  height: 50px;
  color: #399b22;
  font-weight: 400;
  font-size: 16px;
  font-family: inherit;
  display: flex;
  text-shadow: 0 0 1px rgb(75, 194, 49, 0.8);
  gap: 5px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 14px 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.buttonArrow {
  color: #399b22;
  font-size: 25px;
  display: flex;
  text-shadow: 0 0 4px rgb(75, 194, 49, 0.8);
  margin-top: 3px;
  align-items: center;
}

.main-link {
  text-decoration: none;
}

/* Select CSS */
/*  */
.select-wrapper {
  position: relative;
  width: 200px;
}

.selectInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 40px;
  padding: 10px 30px 10px 10px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
  cursor: pointer;
}

.select-wrapper .select-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #333;
  pointer-events: none;
}

.selectInput:focus {
  outline: none;
}

.select-wrapper::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #333;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}

.select-wrapper:focus-within::before {
  opacity: 1;
}

.dropdown-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dropdown-section label {
  margin-right: 10px;
  color: gray;
  text-transform: uppercase;
}

.dropdown {
  position: relative;
}

.dropdown-selected-option {
  cursor: pointer;
  font-size: 12px;

  padding: 5px 20px;
  min-width: 150px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  font-size: 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-option:hover {
  background-color: #ccc;
}

.checkmark {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  /* background-color: green; */
  color: green;
  text-align: center;
  line-height: 16px;
}

.dropdown-option {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

/* .dropdown-option.checked {
  font-weight: bold;
} */
@media screen and (max-width: 550px) {
  .arrowButton {
    width: 80vw;
  }
}

.blockButton {
  position: absolute;
  width: 150px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.682);
  top: calc(50% - 30px);
  border-radius: 10px;
  padding: 15px 0px;
  cursor: not-allowed;
}
