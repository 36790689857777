.mainContainer {
  margin: 10px auto;
  /* border: 1px solid #22222240; */
  border-radius: 2px;
}

.resultBox {
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #22222240;
  border-bottom: 1px solid #22222240;
  cursor: pointer;
}
