.input {
  padding: 10px 12px !important;
  border-radius: 8px !important;
  margin: 0 !important;
  /* background-color: red !important; */
}

.input:focus {
  box-shadow: none !important;
  border-color: #399b22 !important;
}

.input::placeholder {
  color: #22222260 !important;
}
