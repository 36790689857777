.nameValuesContainer {
  background-color: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 16px 14px 30px 14px;
  margin: 15px 0px;
  border-radius: 8px;
}

.mainContainer {
  width: 550px;
  border-radius: 8px;
  background-color: #f2f0f8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.searchContainer {
  width: 100%;
  height: 60px;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.searchContainer p {
  position: absolute;
  font-size: 16px;
  top: 12px;
  right: 16px;
  color: #399b22;
  cursor: pointer;
}

.inputContainer {
  position: relative;
  width: 90%;
  margin: 40px auto 0px auto;
}

.inputContainer input {
  width: 100%;
  border-radius: 12px;
  height: 40px;
  background-color: #f4f0f4;
  outline: none;
  border: none;
  padding: 10px 10px 10px 38px;
}

.inputContainer svg {
  position: absolute;
  top: 9px;
  left: 10px;
}

.optionContainer {
  margin-top: 50px;
  background-color: white;
  max-height: 500px;
  overflow: auto;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.optionContainer div {
  width: 100%;
  padding: 12px 10px 12px 15px;
  font-size: 16px;
  cursor: pointer;
}

.optionContainer div:hover {
  background-color: #efebf2;
  transition: all 150ms ease-in-out;
}

.optionContainer::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}
.optionContainer::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
.optionContainer::-webkit-scrollbar-thumb {
  background-color: #f2f0f8; /* color of the scroll thumb */
  border-radius: 6px; /* roundness of the scroll thumb */
}

.selectedValContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  background-color: transparent;
  border: 1px solid #cfd5db;
  margin: 18px auto;
  border-radius: 8px;
  min-height: 50px;
  position: relative;
}

@media screen and (max-width: 650px) {
  .mainContainer {
    width: 90%;
    margin: auto;
  }
}
