.mainContainer {
  background-color: #f2f0f8;
  width: 400px;
  min-height: 550px;
  border-radius: 4px;
  padding: 30px 16px;
  margin-top: 8px;
}

.nuevo {
  width: 100%;
  min-height: 35px;
  background-color: white;
  padding: 10px 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.doubleBox {
  width: 100%;
  padding: 5px 16px;
  border-radius: 10px;
  background-color: white;
}

.doubleBox div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
}
