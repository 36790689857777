.pickerOptionsViewMainDiv {
  display: grid;
  /* gap: 20px; */
}

.listContainer {
  border: 1px solid #22222240;
  border-radius: 8px;
}

.listContainer span {
  list-style: none;
  cursor: pointer;
  padding: 16px 12px;
  min-height: 50px;
  display: block;
  width: 100%;
}

.listContainer span:hover {
  background-color: #efebf2;
  transition: all 150ms ease-in-out;
}
