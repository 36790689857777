.inputContainer {
  background-color: white;
  border: 1px solid #ced4da;
  padding: 5px 10px;
  border-radius: 8px;
  min-height: 45px;
}

.toggleButtonContainer {
  background-color: #ced4da;
  border-radius: 8px;
  padding: 4px 8px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  gap: 5px;
  position: relative;
  z-index: 0;
}

.toggleButtonContainer p {
  width: 45px;
  text-align: center;
  margin-top: 15px;
  z-index: 2;
  cursor: pointer;
}

.selectedToggleButton {
  position: absolute;
  top: 3px;
  transition: all 150ms ease-in-out;
  width: 45px;
  height: 28px;
  border-radius: 8px;
  z-index: 1;
  background-color: white;
  cursor: pointer;
}
