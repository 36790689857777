.dropdownContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  background-color: transparent;
  border: 1px solid #cfd5db;
  margin: 18px auto 0px auto;
  border-radius: 8px;
  height: 50px;
  position: relative;
}

.dropdownLabel {
  font-size: 16px;
  margin-top: 15px;
}

.selectedOption {
  display: flex;
  align-items: center !important;
  margin-top: 15px;
  gap: 3px;
  cursor: pointer;
}

.selectedOption p {
  color: #22222260;
}

.selectedOption svg {
  color: #22222260;
  margin-bottom: 16px;
}

.selectContainer {
  position: absolute;
  top: -55px;
  right: 28px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.option {
  padding: 10px 10px 8px 18px;
  font-size: 16px;
  cursor: pointer;
}

.option:hover {
  background-color: #22222230;
  transition: all 150ms ease;
}
